const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150 },
    {
        field: 'area',
        headerName: 'Area',
        headerClassName: 'table-header',
        width: 270,
        editable: true,
    },
    {
        field: 'station_code',
        headerName: 'Station Code',
        headerClassName: 'table-header',
        width: 150,
        editable: true,
    },
    {
        field: 'role',
        headerName: 'Role',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'rate1',
        headerName: 'MWB',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'rate2',
        headerName: 'LWB',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'rate3',
        headerName: 'SWB',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'vanrate1',
        headerName: 'MVB Rental',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'vanrate2',
        headerName: 'LWB Rental',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'vanrate3',
        headerName: 'SWB Rental',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'incentive',
        headerName: 'Incentive',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'incentive_notes',
        headerName: 'Incentive Notes',
        headerClassName: 'table-header',
        width: 330,
        editable: true,
    },
    {
        field: 'address1',
        headerName: 'Address 1',
        headerClassName: 'table-header',
        width: 150,
        editable: true,
    },
    {
        field: 'address2',
        headerName: 'Address 2',
        headerClassName: 'table-header',
        width: 330,
        editable: true,
    },
    {
        field: 'town',
        headerName: 'Town',
        headerClassName: 'table-header',
        width: 200,
        editable: true,
    },
    {
        field: 'county',
        headerName: 'County',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'postcode',
        headerName: 'Post Code',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'map',
        headerName: 'Map',
        headerClassName: 'table-header',
        width: 400,
        editable: true,
    },
    {
        field: 'contact',
        headerName: 'Contact',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'phone',
        headerName: 'Phone',
        headerClassName: 'table-header',
        width: 150,
        editable: true,
    },
    {
        field: 'email',
        headerName: 'Email',
        headerClassName: 'table-header',
        width: 150,
        editable: true,
    },
    {
        field: 'status',
        headerName: 'Status',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 100,
        editable: true,
    },
    {
        field: 'updatedBy',
        headerName: 'Updated By',
        headerClassName: 'table-header',
        width: 200,
        editable: true,
    },
]

export default columns;