import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import columns from "./daypay";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import { alpha, styled } from '@mui/material/styles';
import Calendar from "react-calendar";
import Select from 'react-select';
import payDataBuild from "./dayPayDataBuild";
import AuthService from "../../services/auth.service";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '80%', minHeight: '20px', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
  menu: base => ({
      ...base,
      zIndex: 100,
      width: '101%',
      paddingLeft:'1%',
      marginLeft:'0%',
      
  }), 
}

function getweekNo(d) {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  var yearStart = new Date(d.getFullYear(), 0, 1);
  var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  return [d.getFullYear(), weekNo];
}

function weeksInYear(year) {
  var month = 11,
    day = 31,
    week;
  do {
    let d = new Date(year, month, day--);
    week = getweekNo(d)[1];
  } while (week == 1);

  return week;
}

function subtractTime(time1, time2) {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);
  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;
  let diffMinutes = totalMinutes1 - totalMinutes2;

  if (diffMinutes < 0) {
    diffMinutes += 1440;
  }

  const resultHours = Math.floor(diffMinutes / 60);
  const resultMinutes = diffMinutes % 60;
  const formattedResult = `${resultHours.toString().padStart(2, '0')}:${resultMinutes.toString().padStart(2, '0')}`;

  return formattedResult;
}

function isOverElevenHours(time) {
  const [hours, minutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes > 660;
}

function isDateInWeek(date, year, weekNumber) {
  function getDateRangeOfWeek(weekNo, year) {
    const firstDayOfYear = new Date(year, 0, 1); // January 1st of the year
    const dayOffset = firstDayOfYear.getDay(); // Sunday = 0, Monday = 1, etc.
    const startOfFirstWeek = new Date(firstDayOfYear);
    startOfFirstWeek.setDate(firstDayOfYear.getDate() - dayOffset); // Adjust to the Sunday of the first week

    const startOfRequestedWeek = new Date(startOfFirstWeek);
    startOfRequestedWeek.setDate(startOfFirstWeek.getDate() + (weekNo - 1) * 7);

    const endOfRequestedWeek = new Date(startOfRequestedWeek);
    endOfRequestedWeek.setDate(startOfRequestedWeek.getDate() + 7);

    return [startOfRequestedWeek, endOfRequestedWeek];
  }

  const inputDate = new Date(date);
  const [startOfWeek, endOfWeek] = getDateRangeOfWeek(Number(weekNumber), year);
  return inputDate >= startOfWeek && inputDate <= endOfWeek;
}

function getDateRangeOfWeek(weekNo, year) {
  const firstDayOfYear = new Date(year, 0, 1);
  const daysToFirstWeek = (firstDayOfYear.getDay() + 6) % 7 + 1;
  const startOfRequestedWeek = new Date(firstDayOfYear);
  startOfRequestedWeek.setDate(firstDayOfYear.getDate() + (weekNo - 1) * 7 - daysToFirstWeek);
  const endOfRequestedWeek = new Date(startOfRequestedWeek);
  endOfRequestedWeek.setDate(startOfRequestedWeek.getDate() + 6);
  
  return startOfRequestedWeek.toLocaleDateString('en-GB')+' - '+endOfRequestedWeek.toLocaleDateString('en-GB');
}

const DayPayment = () => {
    const user = AuthService.getCurrentUser() 
    const [displayData, setDisplayData] = useState([]);
    const [ date, setDate ] = useState(new Date());
    const [ year, setYear ] = useState(new Date().getFullYear());
    const [ weekNo, setWeekNo ] = useState(year+'-'+getweekNo(new Date())[1].toString().padStart(2, '0'));
    const [ depot, setDepot ] = useState('Select...');
    const [ depotOptions, setDepotOptions ] = useState([]);
    const [ yearOptions, setYearOptions ] = useState([]);
    const [ weekOptions, setWeekOptions ] = useState([]);
    const [ dayOfWeew, setDayOfWeek ] = useState('');
    const [ tableD, setTableD ] = useState([]);
    const [ rateCard, setRateCard ] = useState([]);
    const [ additionalTableD, setAdditionalTableD ] = useState([]);
    const [ field, setField ] = useState('');
    const [ partialData, setPartialData ] = useState([])
    const [ associatesNS, setAssociatesNS ] = useState([])
    const [ checkDate, setCheckDate ] = useState(false)
    const [ colDisplay, setColDisplay ] = useState([])
    const [ uploadCheck, setUploadCheck ] = useState(false)
    const [ uploadMessage, setUploadMessage ] = useState('')
    const [ columnVis, setColumnVis ] = useState({id: false})

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
              <div className="inlines" style={{paddingRight: '30px', borderRight: '0.5px solid #d3d3d3', marginBottom: '5px'}} >
                
                {/*<GridToolbarExport />
                <Button className="incGridButton" onClick={e => setOpenCsv(true)}> 
                  <FileUploadOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  IMPORT
                </Button>
                <GridToolbarQuickFilter />
                <Button className="incGridButton" onClick={e => setOpenAdd(true)}> 
                    <LibraryAddOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  NEW ENTRY
                </Button>*/}
                <h5 className="incGridButton">Date</h5>
                <Calendar className="dayPayCalendar" onChange={e => setDate(e.toLocaleDateString('en-GB').split('/')[2]+'-'+e.toLocaleDateString('en-GB').split('/')[1]+'-'+e.toLocaleDateString('en-GB').split('/')[0])} value={date}/>
                <div>
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                </div>
              </div>
              <div className="inlines" style={{paddingRight: '30px', borderRight: '0.5px solid #d3d3d3', paddingBottom: '40px', marginTop: '5px !important', paddingLeft: '20px'}} >
                <h5 className="incGridButton">Depot</h5>
                  <Select 
                      options={depotOptions}
                      styles={colourStyles}
                      value={{label: depot, value: depot}}
                      onChange={e => setDepot(e.value)}
                  />
                  <h5 className="incGridButton">Year</h5>
                  <Select 
                      options={yearOptions}
                      styles={colourStyles}
                      value={{label: year, value: year}}
                      onChange={e => setYear(e.value)}
                  />
                  <h5 className="incGridButton">Week No</h5>
                  <Select 
                      options={weekOptions}
                      styles={colourStyles}
                      value={{label: weekNo, value: weekNo}}
                      onChange={e => setWeekNo(e.value)}
                  />
                  <h5 className="incGridButton">Date range in week {weekNo.split('-')[1]}</h5>
                  <p>{getDateRangeOfWeek(weekNo.split('-')[1], year)}</p>
                  {checkDate ? <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={uploadDay} >
                      <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Upload Payment</span>
                      <div class="fill-container"></div>
                  </button> : <p><i>Day already uploaded. <br></br>If the day was missed please contact <br></br>office for upload. <br></br><span style={{color: 'red'}}>*Any partial values will not be saved.</span></i></p>}
              </div>
              <div className="inlines" style={{paddingRight: '30px', borderRight: '0.5px solid #d3d3d3', paddingBottom: '160px', marginTop: '5px !important', paddingLeft: '20px'}} >
                <table className="dayPayTable">
                  <thead>
                    <tr>
                      <th>Routes</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableD}

                  </tbody>
                </table>
                {/*<div className="flex">
                  <div className="timeDiv" style={{marginTop: '10px'}}>
                    <p className="pTime">Cycle 1</p>
                    <TimePicker 
                        name='time'
                        placeholder='time'
                        //value={input.time}
                        //onChange={event => handleChangeTime(index, event, 'time')}
                    />
                    
                  </div>
                  <div className="timeDiv" style={{marginLeft: '30px', marginTop: '10px'}}>
                    <p className="pTime">Cycle 2</p>
                    <TimePicker 
                        name='time'
                        placeholder='time'
                        //value={input.time}
                        //onChange={event => handleChangeTime(index, event, 'time')}
                    />
                    
                </div>
                </div>
                <div className="timeDiv" style={{marginTop: '10px'}}>
                  <p className="pTime">Cycle 3</p>
                  <TimePicker 
                      name='time'
                      placeholder='time'
                      //value={input.time}
                      //onChange={event => handleChangeTime(index, event, 'time')}
                  />
                  
                </div>*/}
              </div>
              <div className="inlines" style={{paddingRight: '30px', borderRight: '0.5px solid #d3d3d3', paddingBottom: '140px', marginTop: '5px !important', paddingLeft: '20px'}} >
                <table className="dayPayTable">
                  <thead>
                    <tr>
                      <th>Additional Payments</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {additionalTableD}

                  </tbody>
                </table>
              </div>
              <div className="inlines" style={{paddingRight: '30px', paddingBottom: '140px', marginTop: '5px !important', paddingLeft: '20px', maxWidth: '300px'}} >
                <h5 className="incGridButton">Notes</h5>
                <br></br>
                <p>If 'Adjustment for Support' is minus/negative (RED) this means your support payments are not balanced and you are spending £, if this is the case please ensure accurate notes are left for checking</p>
              </div>
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
      const currentYear = new Date().getFullYear();
      
      setYearOptions([
        { value: currentYear-1, label: currentYear-1 },
        { value: currentYear, label: currentYear },
        { value: currentYear+1, label: currentYear+1 },
      ])
      
      UserService.getAssociatesNamesSkno().then(
        response => {
          setAssociatesNS(response.data)
        }
      ).catch(err => console.log(err))

      UserService.getStations().then(
        response =>{
          const data = response.data;
          let depotOptionsPlc = [];
          data.map((d) => {
            if(!d.station_code.match('SD')){
              depotOptionsPlc.push({ value: d.station_code, label: d.station_code });
            }
          });
          setDepotOptions(depotOptionsPlc);
        }
      ).catch(err => console.log(err))

      UserService.getRateCard().then(
        response =>{
          const data = response.data;
          console.log(data)
          setRateCard(data);
        }
      ).catch(err => console.log(err))
      
    }, []);

    useEffect(() => {
      const weeks = weeksInYear(year);
      let weekOptionsPlc = [];

      for (let i = 1; i <= weeks; i++) {
        weekOptionsPlc.push({ value: year+'-'+i.toString().padStart(2, '0'), label: year+'-'+i.toString().padStart(2,'0') });
      }
    
      setWeekOptions(weekOptionsPlc);
    }, [year]);

    useEffect(() => {
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      setDayOfWeek(days[new Date(date).getDay()])
    }, [date]);

    useEffect(() =>{
      const dataSend = {date: date, station: depot}
      UserService.getPartial(dataSend).then(
        response =>{
          console.log(response.data)
          const dataRec = response.data
          console.log(dataRec)
          setPartialData(dataRec)
        }
      ).catch(err => console.log(err))
    },[date, depot])

    useEffect(() => {
      setDisplayData([])
      if(depot !== 'Select...' && weekNo && dayOfWeew && year ){
        const data = {station: depot, week_no: weekNo, year_no: year, day: dayOfWeew}
        UserService.getDaySchedule(data).then(
          response =>{
            const data = response.data;
            let plc = []
            console.log(isDateInWeek(date, year, weekNo.split('-')[1]))
            if(isDateInWeek(date, year, weekNo.split('-')[1])){
                data?.map((d) => {
                  plc.push({id: d.entry, associate_name: d.name, category: d[dayOfWeew], qty: '1', route: '', orh_wave_time: '', orh_dispatch_time: '', orh_end_time: '', orh_actual_duration: '', unlinked_miles: '', unlinked_congestion: '', unlinked_tool_crossing: '', unlinked_away: '', orh_late_wave: '', notes: '', orh_notes: '', rescue2: '', rescue4: '', rescue6: '', support: '', deduction: ''})
                })
                plc?.map(p =>{
                  partialData?.map(d =>{
                    
                    if(p.associate_name == d.name && d.week_no == weekNo && date == d.date){
                      
                      p.route = d.route_code
                      p.orh_wave_time = d.wave_time
                      p.orh_dispatch_time = d.dispatch_time
                      p.orh_end_time = d.end_time 
                      p.orh_actual_duration = d.duration
                      p.unlinked_miles = d.miles 
                      p.unlinked_congestion = d.congestion
                      p.unlinked_tool_crossing = d.tool_crossing
                      p.unlinked_away = d.away 
                      p.orh_late_wave = d.late_wave
                      p.notes = d.notes
                      p.orh_notes = d.orh_notes
                      p.rescue2 = d.rescue_2 
                      p.rescue4 = d.rescue_4
                      p.rescue6 = d.rescue_6 
                      p.support = d.support
                      p.deduction = d.deductions
                    }
                  })
                })
            }
            setDisplayData(plc);
          }
        ).catch(err => console.log(err))
      }
    }, [weekNo, dayOfWeew, depot, year, date, partialData]);

    useEffect(() => {
      let plc = []
      let countPlc = []
      let tableData = []
      let tableDataLen = 0
      const headCollumn = ['Adjustment for Support', 'Away Team Rate', 'Congestion Charge', 'Dartford Charge', 'Milage Reclaim', 'Unplanned Delay']
      const aliasHeadCollumn = ['adjustment', 'unlinked_away', 'unlinked_congestion', 'unlinked_tool_crossing', 'unlinked_miles', 'orh_late_wave']

      console.log('this')
      if(displayData.length > 0){
        displayData?.map((d) => {
          plc.push(d.category)
        })
      }
      plc = [...new Set(plc)]

      plc.map((p) => {
        let count = 0;
        displayData?.map((d) => {
          if(d.category === p){
            count++;
          }
        })
        countPlc.push(count)
      })

      plc?.map((p, index) => {
        rateCard?.map((r) => {
          if(p == r.schedule_shortcode){
            tableData.push(<tr>
                            <td>{r.description}</td>
                            <td>{countPlc[index]}</td>
                          </tr>)
          }
        })              
      })
      
      tableDataLen = tableData.length
      for(let i = tableDataLen; i < 10; i++){
        tableData.push(<tr>
                        <td style={{color: 'white'}}> - </td>
                        <td style={{color: 'white'}}> - </td>
                      </tr>)
      }
      setTableD(tableData)

      plc = []
      countPlc = []
      tableData = []

      aliasHeadCollumn?.map((p) => {
        let count = 0;
        let adj = 0;
        if(p !== 'adjustment'){
          displayData?.map((d) => {
            if(d[p] !== 0){
              count += Number(d[p])
            }
          })
        }else{
          displayData?.map((d) => {
            console.log(adj)
            if(d.support){
              adj += Number(d.support)
            }
            if(d.deduction){
              if(d.deduction < 0){
                adj += Number(d.deduction)
              }else{
                adj -= Number(d.deduction)
              }
            }
          })
          count = adj
        }
        countPlc.push(count)
      })
      console.log(countPlc)
      aliasHeadCollumn?.map((p, index) => {
        if(p !== 'adjustment'){
          tableData.push(<tr>
                          <td>{headCollumn[index]}</td>
                          <td>{countPlc[index]}</td>
                        </tr>)
        }else{
          tableData.push(<tr>
            <td>{headCollumn[index]}</td>
            <td style={{backgroundColor: countPlc[index] < 0 ? '#f7a49e' : 'white'}}>{countPlc[index] < 0 ? '-£'+countPlc[index].toString().split('-')[1] : '£'+countPlc[index]}</td>
          </tr>)
        }
      })

      tableDataLen = tableData.length
      for(let i = tableDataLen; i < 10; i++){
        tableData.push(<tr>
          <td style={{color: 'white'}}> - </td>
          <td style={{color: 'white'}}> - </td>
        </tr>)
      }
      setAdditionalTableD(tableData)
    }, [displayData, field]);

    const handleEdit = (params) => {
      let plc = displayData
      let dataSend = {
        name: params.associate_name , 
        service_type: params.category,
        qty: params.qty,
        route_code: params.route,
        wave_time: params.orh_wave_time,
        dispatch_time: params.orh_dispatch_time,
        end_time: params.orh_end_time,
        duration: params.orh_actual_duration,
        miles: params.unlinked_miles,
        congestion: params.unlinked_congestion,
        tool_crossing: params.unlinked_tool_crossing,
        away: params.unlinked_away,
        late_wave: params.orh_late_wave,
        rescue_2: params.rescue2,
        rescue_4: params.rescue4,
        rescue_6: params.rescue6,
        support: params.support,
        deductions: params.deduction,
        notes: params.notes,
        orh_notes: params.orh_notes,
        year: year,
        week_no: weekNo,
        station: depot,
        date: date
      }
      console.log(dataSend)
      plc.map((d) => {
        if(d.id === params.id){
          d[field] = params[field]
          if(field == 'orh_dispatch_time' || field == 'orh_end_time'){
            if(d.orh_dispatch_time && d.orh_end_time){
              d.orh_actual_duration = subtractTime(d.orh_end_time, d.orh_dispatch_time)
              params.orh_actual_duration = d.orh_actual_duration
            }
          }
        }
      })
      setDisplayData(plc)
      setField('')
      if(checkDate){
        UserService.updatePartial(dataSend).then(
          response =>{
            console.log(response.data)
          }
        ).catch(err => console.log(err))
        
      }
      return params
    }

    useEffect(() =>{
      const currentDate = new Date().getFullYear()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0')
      //console.log(new Date(currentDate))
      if(/*new Date(date).getTime() <= new Date(currentDate).getTime() &&*/ new Date(date).getTime() >= (new Date(currentDate).getTime()-(86400000*2))){
        setCheckDate(true)
        console.log(new Date(date))
        let colPlc = columns
        colPlc?.map((col, index) =>{
          col.editable = false
        })
        setColDisplay(colPlc)
      }else{
        let colPlc = columns
        colPlc?.map((col, index) =>{
          col.editable = true
        })
        setColDisplay(colPlc)
        setCheckDate(false)
      }
      
    },[date])

    const uploadDay = () =>{
      const dataBuild = payDataBuild(displayData, rateCard, associatesNS, date, weekNo, depot, user.username)
      console.log(dataBuild)
      const dataSend = {schedule_date: date, station: depot}
      UserService.deleteAndUpdatePayments(dataSend).then(
        del =>{
          console.log(del.data)
          UserService.insertPayments(dataBuild).then(
            response =>{
              setUploadMessage('Upload complete.')
              setUploadCheck(true)
              console.log(response.data)
            }
          ).catch(err => {
            setUploadMessage('Upload failed.')
            setUploadCheck(true)
            console.log(err)
          })
        }
      ).catch(err => {
        setUploadMessage('Upload failed.')
        setUploadCheck(true)
        console.log(err)
      })
    }

    return(
        <div className="dashArch">
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Day Payments</h3>
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop:2 }}>
                <StripedDataGridPro
                    pagination
                    rows={displayData}
                    columns={colDisplay.length > 0 ? colDisplay : columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '',  [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#f7a49e',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar,
                      
                    }}
                    disableColumnMenu
                    disableColumnSorting
                    //loading={loading}
                    {...displayData}
                    processRowUpdate={params => handleEdit(params)}
                    onProcessRowUpdateError={err => console.log(err)}
                    //onRowClick={params => console.log(params)}
                    onCellEditStop={params => setField(params.field)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    columnVisibilityModel={columnVis}
                    onColumnVisibilityModelChange={params => setColumnVis(params)}
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                          return '';
                        }
                        return params.field == 'orh_actual_duration' && isOverElevenHours(params.value) ? 'cold' : '' ;
                    }}
                />
            </Box> 
            <React.Fragment >
                <Dialog
                    //fullScreen
                    
                    open={uploadCheck}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >{uploadMessage}</label></DialogTitle>
                    <DialogContent >
                    </DialogContent>
                    <DialogActions>
                        
                        <button class="buttonSkOn" style={{marginTop: '10px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setUploadCheck(false)}>
                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default DayPayment;
